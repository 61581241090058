import React, {useState, useEffect} from "react";
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import {apiRequest} from "../../../apiRquest/apiRequest";
import {Layout} from "../../../components/Layout";
import { Link } from 'gatsby';
import Button from "../../../components/buttons/button";
import ProductBanner from "../../../components/product_banners/product_banners";
import Banner from "../../../imgs/bg_inversiones.jpg";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import LineChart from "../../../components/line_chart/line_chart";
import Filters from "../../../components/filters/filters";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./rentabilidad.css";
import Lupa from "../../../imgs/lupa_search.svg"
/*import "../../pages.css";*/
import axios from "axios";

/*let lineChartData = [];
let value = 50;
for (let i = 0; i < 300; i++) {
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(i);
  value -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
  lineChartData.push({ date: date, value: value });
}*/

const pageTitle = 'Rendimientos de nuestros Fondos de Inversión | Balanz';
const pageDesc = 'Tus ahorros pueden rendir más. Mirá las rentabilidades de todos nuestros Fondos Comunes de Inversión: Renta Fija, Renta Variable, Dolar Linked y CER.';

const slugFondos = [
    {
        id_fondo: 1,
        slug:"retorno-total",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJSVEEiLCJwYW5lbCI6IjEwIn19"
    },
    {
        id_fondo: 2,
        slug:"renta-fija-dolar-linked",
        deeplink: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDUkZBIiwicGFuZWwiOiIxMCJ9fQ"
    },
    {
        id_fondo: 3,
        slug:"ahorro",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDQUhBIiwicGFuZWwiOiIxMCJ9fQ"

    },
    {
        id_fondo: 4,
        slug:"abierto-pymes",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDQVBZQSIsInBhbmVsIjoiMTAifX0"

    },
    {
        id_fondo: 5,
        slug:"excalibur-regisseur",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkVYQ0FMSUEiLCJwYW5lbCI6IjEwIn19"

    },
    {
        id_fondo: 6,
        slug:"renta-fija-estrategica",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDRVNUUkEiLCJwYW5lbCI6IjEwIn19"

    },
    {
        id_fondo: 7,
        slug:"acciones",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDQUNDQSIsInBhbmVsIjoiMTAifX0"

    },
    {
        id_fondo: 8,
        slug:"ahorro-en-dolares",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJBSFVTREEiLCJwYW5lbCI6IjEwIn19"

    },
    {
        id_fondo: 41,
        slug:"estrategia-i",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkVTVFJBMUIiLCJwYW5lbCI6IjEwIn1"

    },
    {
        id_fondo: 9,
        slug:"renta-fija-en-dolares",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDUkRvbGFyQSIsInBhbmVsIjoiMTAifX0"

    },
    {
        id_fondo: 10,
        slug:"sudamericano",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDU1VEQSIsInBhbmVsIjoiMTAifX0"

    },
    {
        id_fondo: 11,
        slug:"institucional-inflation-linked",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IklOU1RJVFVBIiwicGFuZWwiOiIxMCJ9fQ"

    },
    {
        id_fondo: 12,
        slug:"renta-fija-oportunity",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6Ik9QUE9SQSIsInBhbmVsIjoiMTAifX0"

    },
    {
        id_fondo: 13,
        slug:"gestion",
    },
    {
        id_fondo: 31,
        slug:"money-market",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDTU1BIiwicGFuZWwiOiIxMCJ9fQ"

    },
    {
        id_fondo: 33,
        slug:"abierto-infraestructura",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJJTkZSQUIiLCJwYW5lbCI6IjEwIn19"

    },
    {
        id_fondo: 34,
        slug:"performance-i",
    },
    {
        id_fondo: 38,
        slug:"performance-ii",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IlBFUjJBIiwicGFuZWwiOiIxMCIsImlkQ2FtcGFpZ24iOiJGQ0lQRVIyUkVOVEFCSUxJREFELUZDSS1SRU5UQUJJTElEQUQtMjAyNDA5MzAtREVFUExJTksiLCJjYW1wYWlnbl9pZCI6IkZDSVBFUjJSRU5UQUJJTElEQUQtRkNJLVJFTlRBQklMSURBRC0yMDI0MDkzMC1ERUVQTElOSyJ9fQ"
    },
    {
        id_fondo: 37,
        deeplink: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IlBFUjNBIiwicGFuZWwiOiIxMCJ9fQ"
    },
    {
        id_fondo: 36,
        slug:"performance-iv",
    }
    ,
    {
        id_fondo: 35,
        slug:"performance-v",
    },
    {
        id_fondo: 39,
        slug:"performance-xi",
    },
    {
        id_fondo: 42,
        slug:"performance-xii",
    },
    {
        id_fondo: 40,
        slug:"performance-x-equity-selection",
        deeplink: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IlBFUjEwQSIsInBhbmVsIjoiMTAifX0"
    },
    {
        id_fondo: 45,
        slug:"desarollo",
        deeplink:"https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkRFU0FBIiwicGFuZWwiOiIxMCJ9fQ"

    },
    {
        id_fondo: 46,
        slug: "soja",
        deeplink: "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVJbnN0cnVtZW50byIsDQoJImZvbmRvIjoiU09KQUEiLA0KCSJwYW5lbCI6IjEwIiwNCgkiaWRDYW1wYWlnbiI6IkZDSVNPSkFSRU5UQUJJTElEQURFUy1GQ0ktUkVOVEFCSUxJREFELTIwMjQxMjMwLURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJGQ0lTT0pBUkVOVEFCSUxJREFERVMtRkNJLVJFTlRBQklMSURBRC0yMDI0MTIzMC1ERUVQTElOSyINCiAgfX0"  
    },
    {
        id_fondo: 47,
        deeplink: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkNSRUNJQSIsInBhbmVsIjoiMTAiLCJpZENhbXBhaWduIjoiRkNJQ1JFQ0lNSUVOVE9SRU5UQUJJTElEQUQtRkNJLVJFTlRBQklMSURBRC0yMDI0MDkzMC1ERUVQTElOSyIsImNhbXBhaWduX2lkIjoiRkNJQ1JFQ0lNSUVOVE9SRU5UQUJJTElEQUQtRkNJLVJFTlRBQklMSURBRC0yMDI0MDkzMC1ERUVQTElOSyJ9fQ"
    },
    {
        id_fondo: 48,
        deeplink: "https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVJbnN0cnVtZW50byIsDQoJImZvbmRvIjoiRVNUUkEzIiwNCgkicGFuZWwiOiIxMCIsDQoJImlkQ2FtcGFpZ24iOiJGQ0lFU1RSQVRFR0lBM1dFQi1GQ0ktUkVOVEFCSUxJREFELTIwMjQxMTI4LURFRVBMSU5LIiwNCgkiY2FtcGFpZ25faWQiOiJGQ0lFU1RSQVRFR0lBM1dFQi1GQ0ktUkVOVEFCSUxJREFELTIwMjQxMTI4LURFRVBMSU5LIg0KICB9fQ"
    },
    {
        id_fondo: 50,
        deeplink: "https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJmb25kbyI6IkJDTU1VU0RBIiwicGFuZWwiOiIxMCIsImlkQ2FtcGFpZ24iOiJGQ0lNTVVTRFJFTlRBQklMSURBRC1GQ0ktUkVOVEFCSUxJREFELTIwMjQwOTMwLURFRVBMSU5LIiwiY2FtcGFpZ25faWQiOiJGQ0lNTVVTRFJFTlRBQklMSURBRC1GQ0ktUkVOVEFCSUxJREFELTIwMjQwOTMwLURFRVBMSU5LIn19"
    }

    ]
const { SearchBar } = Search;
const columns = [
    {
        dataField: "fondolink",
        text: "Fondo",
        sort: true,
        filterValue:(fondolink) => fondolink.name,
        formatter: (fondolink, row, rowIndex) => {
            //console.log('row', row.ticker)
            let fundCustomName = fondolink.name;

            if (fundCustomName.indexOf("Clase") > 0) {
                let fundname = fundCustomName.substring(0, fundCustomName.indexOf("Clase"));
                let fundclass = fundCustomName.substring(fundCustomName.indexOf("Clase") - 1);
                if(fondolink.slug){
                    return (
                        <Link style={{display: "block"}} to={`/inversiones/fondos/${fondolink.slug}`}>
                            <strong>
                                {fundname}
                            </strong><br/>
                            {fundclass}<br/>
                            {row.ticker}
                        </Link>
                    )
                }else{
                    return (
                        <>
                            <strong>
                                {fundname}
                            </strong><br/>
                            {fundclass}<br/>
                            {row.ticker}
                            </>
                    )
                }

            } else {
                if(fondolink.slug) {
                    return (
                        <Link to={`/inversiones/fondos/${fondolink.slug}`}>
                            <strong>
                                {fundCustomName}
                            </strong><br/>
                            {row.ticker}
                        </Link>
                    )
                }else{
                    return (
                        <>
                            <strong>
                                {fundCustomName}
                            </strong><br/>
                            {row.ticker}
                        </>
                    )
                }
            }
        },
        classes: 'bg-lt-grey text-start fondo_col_cell',
        headerClasses: "fondo_col",
    },
    {
        dataField: "var_diaria",
        text: "Var Diaria",
        sort: true,
        classes: 'var-diaria',
        headerClasses: "vardiaria_col",
        formatter: (var_diaria, row, rowIndex) => {
            return (
                <span
                    className={`varitem ${var_diaria.tendency}`}
                    style={{color: var_diaria.tendency, fontWeight: "600"}}
                    key={rowIndex}
                >
                    {(var_diaria?.text !==null)? var_diaria.text : '-'}
         {/* {var_diaria.text}*/}
        </span>
            );
        },
    },
    {
        dataField: "cuota_parte",
        text: "Cuota Parte",
        sort: true,
        headerClasses: "cuotaparte_col",
    },
    {
        dataField: "var_mensual",
        text: "Var Mensual",
        sort: true,
        headerClasses: "varmensual_col",
        formatter: (var_mensual) => {
            /*return (`${var_mensual}%`);*/
            return (`${(var_mensual !==null)? (var_mensual+'%') : '-'}`);
        },
    },
    {
        dataField: "var_anual",
        text: "Var Anual",
        sort: true,
        headerClasses: "varanual_col",
        formatter: (var_anual) => {
            /*return (`${var_anual}%`);*/
            return (`${(var_anual !==null)? (var_anual+'%') : '-'}`);
        },
    },
    {
        dataField: "var_YTD",
        text: "Var YTD",
        sort: true,
        headerClasses: "varytd_col",
        formatter: (var_YTD) => {
           /* return (`${var_YTD}%`);*/
            return (`${(var_YTD !==null)? (var_YTD+'%') : '-'}`);
        },
    },
    {
        dataField: "deeplink",
        text: "",
        headerClasses: "btnfondo_col",
        formatter: (deeplink, row, rowIndex) => {
            //if(row.idFondo !==34 && row.idFondo !== 38){
                //&& rowIndex !== 15 && rowIndex !== 16 && rowIndex !== 12 && rowIndex !== 17 && rowIndex !== 19  
            if(deeplink ) {
                return (
                    <div className="btnholder" key={rowIndex}>
                        {/*<Link to={`/inversiones/fondos/${link}`}>*/}
                        <a href={deeplink} target="_blank">
                            <Button
                                text="Invertir"
                                variant="secondary data_table_button"/>
                        </a>
                    </div>
                );
            }
           // }
        },
    },
];

const defaultSorted = [
    {
        dataField: "name",
        order: "desc",
    },
];

const RentabilidaPage = () => {
    const [fund, setFund] = useState(0);
    const [fundList, setFundList] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [dataInfo, setDataInfo] = useState([]);
    const [dataFondos, setDataFondos] = useState([]);
    const [dataCustom, setDataCustom] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [dataTableOriginal, setDataTableOriginal] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [mobile, setMobile] = useState(undefined);


    useEffect(() => {
        //Get lista hardcodeada en api xyclon para tener los deeplinks y slugs => se guarda en dataCustom
        apiRequest
            .get("/v1/funds/funds_data")
            .then((res) => {
                let fullData = res.data;
                setDataCustom(fullData)
               // console.log(fullData);

                // Pedimos la lista de fondos del nuevo servicio https://www.fondosbalanz.com/API/FCI/Comparativo
                apiRequest
                    //.get("api/v1/funds/funds_list")
                    .post("/v1/funds/comparativo")
                    .then((res) => {
                        let fondosData = res.data;
                        setDataFondos(fondosData);
                         let dataSet = [];
                        let dataIndex = fondosData.findIndex(x => x.idI == 301)
                         let servicesDesarrollo = fondosData.splice(dataIndex,1)[0]  
                         let servicesPerformanceI = fondosData.findIndex(x => x.idI == 232)

                         // Mover perfomance XII abajo del Performance XI
                         let servicesPerformanceXI = fondosData.findIndex(x => x.idI == 263)
                         let servicesPerformanceXII = fondosData.findIndex(x => x.idI == 283)
                         let removePerformanceXII = fondosData.splice(servicesPerformanceXII,1)[0]
                        fondosData.splice(servicesPerformanceXI+1,0,removePerformanceXII)

                         fondosData.splice(servicesPerformanceI,0,servicesDesarrollo)
                        if(fondosData.length > 0) {
                            //iteramos sobre fondosdata que es el listado de balanz https://www.fondosbalanz.com/API/FCI/Comparativo
                            fondosData.forEach((element, index) => {
                                //console.log('element.idFondo', element.idFondo)
                                if(element.idI !== 16) {
        // console.log(element.idI, element.idFondo)
                                    let dataSlug = slugFondos.filter(el => el.id_fondo == element.idFondo); //dataSlug es la info de slugFondos
                                    let fondoCustom = fullData.filter(el => el.CodFondo == element.idFondo); //dataSlug es la info de customData

                                    let fondoslug =  fondoCustom[0]?.slug || dataSlug[0]?.slug || null;
                                    let tendency, text, id_moneda, ticker, patrimonio, cuota_parte, var_mensual, var_anual, var_YTD,moneda = '';



                                    tendency = element.VarDiaria > 0 ? "green" : "red";
                                    text = element.VarDiaria;
                                    id_moneda = element.idMoneda;
                                    ticker = element.Ticker;
                                    patrimonio = element.Patrimonio;
                                    cuota_parte = element.Cotizacion;
                                    var_mensual = element.VarMensual;
                                    var_anual = element.VarAnual;
                                    var_YTD = element.VarYTD;
                                    moneda = ( element.idMoneda == 1) ? 'Pesos' : 'Dolares';


                                    //console.log(dataSlug)

                                    let row = {
                                        idFondo: element.idFondo,
                                        idI: element.idI,
                                        fondo: element.NombreWebConClase,
                                        fondolink: {
                                            name: element.NombreWebConClase,
                                            slug: fondoslug,
                                        },
                                        var_diaria: {
                                            tendency: tendency,
                                            text: text,
                                        },
                                        id_moneda: id_moneda,
                                        ticker: ticker,
                                        patrimonio: patrimonio,
                                        cuota_parte: cuota_parte,
                                        var_mensual: var_mensual,
                                        var_anual: var_anual,
                                        var_YTD: var_YTD,
                                        link: fondoslug,
                                        deeplink: fondoCustom[0]?.deeplink || dataSlug[0]?.deeplink || null,
                                        key: index,
                                        moneda: moneda,
                                        TipoFondo: element.TipoFondo || fondoCustom[0].TipoFondo,
                                        PerfilInversor: element.PerfilInversor || fondoCustom[0].PerfilInversor,
                                    };

                                    if(row.TipoFondo =='Transaccionales'){
                                        row.TipoFondo = 'Transaccional';
                                    }

                                    dataSet.push(row);
                                }
                            });
                            setDataTable(dataSet);
                            setDataTableOriginal(dataSet);
                            setFundList(dataSet);
                            setFund(dataSet[0].idFondo)

                        }

                    })
                    .catch((err) => {
                        console.log(err);
                    });

            })
            .catch((err) => {
                console.log(err);
            });

    }, []);

    useEffect(() => {
        if (fund > 0) {
            apiRequest
                //.get(`money-market-data/cuotaparte${fund}-historico.json`)
                .post("/v1/funds/history",
                    { id: fund})
                .then((res) => {
                //console.log(res.data, "data set");
                let dataSet = [];
                res.data.forEach((element) => {
                    dataSet.push({date: element.Fecha, value: element.ValorCuotaparte});
                });
                setLineChartData(dataSet);
            });
        }
    }, [fund]);

    useEffect(() => {
        const handleMobile = () => {
            //console.log("is mobile?");
            setMobile(window.innerWidth);
        };
        handleMobile();
        window.addEventListener("resize", handleMobile);
        return () => {
            window.removeEventListener("resize", handleMobile);
        };
    }, []);
    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerRentabilidades"
                bannerTrasparecy="bannerMask"
                productTitle="Rentabilidades FCI"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={false}
                bannerTextClassName="bannerTextInversiones"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <section className="fullwidth rentabilidadestable">
                <Container>
                    <Row className="g-0 d-flex justify-content-start">
                        <h2>Fondos Balanz</h2>
                        <Col xs={12} className="pb-0 pt-sm-2">
                            <Row className="d-flex justify-content-center g-0 filters-holder">
                                <Filters
                                    dataTableOriginal={dataTableOriginal}
                                    optionList={optionList}
                                    setOptionList={setOptionList}
                                    setDataTable={setDataTable}
                                    mobile={mobile}
                                />

                            </Row>
                            <Row className="d-flex justify-content-center g-0 py-5">
                                <Col xs={12}>
                                    {dataTable.length > 0 && (

                                        <ToolkitProvider
                                            bootstrap4
                                            keyField="key"
                                            data={dataTable}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            search
                                        >
                                           
                                            {
                                                props => (
                                                    <div className="toolkit-holder">
                                                        <div className="search-rentabilidades">
                                                        <SearchBar { ...props.searchProps }
                                                            className= "search-rentabilidades-input"
                                                            placeholder= "Buscar por fondo"
                                                            srText= ""
                                                        />
                                                            <img src={Lupa} alt={'buscar'} />
                                                        </div>
                                                        <BootstrapTable { ...props.baseProps }
                                                         classes="custom_table comparative-table table-responsive"
                                                         headerWrapperClasses="data_table_header"
                                                         wrapperClasses="table-responsive"
                                                        />
                                                        {/*<BootstrapTable { ...props.baseProps }
                                                         classes="custom_table comparative-table table-responsive"
                                                         headerWrapperClasses="data_table_header"
                                                         bootstrap4
                                                         keyField="key"
                                                         data={dataTable}
                                                         columns={columns}
                                                         defaultSorted={defaultSorted}
                                                         wrapperClasses="table-responsive"
                                                        />*/}

                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>


                                    )}
                                    {/*dataTable.length > 0 && mobile < 992 && (
                                        <MoneyCards dataList={dataTable} />
                                      )*/}

                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="fullwidth rentabilidadesgrafico">
                <Container>
                    <Row className="d-flex justify-content-center g-0">
                        <Col xs={12}>
                            <div>
                                <p className='base-text'>Seleccionar Fondo:</p>
                                <select id="rentabilidad_select"
                                    value={fund}
                                    className="rentabilidad_select"
                                    onChange={(e) => {
                                        setFund(e.target.value);
                                    }}
                                >
                                    <option value={0} key="0" disabled>
                                        Seleccione
                                    </option>

                                    {fundList.length > 0 &&
                                    fundList.map((item, index) => {
                                        return (
                                            <option value={item.idFondo} key={index}>
                                                {item.fondo}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            {lineChartData.length > 0 && (
                                <LineChart dataSet={lineChartData} title=""/>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"rentabilidades-fci"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default RentabilidaPage;
